.page-container {
  @extend %container-sm;
}

.page-body {
  > p, > dl, > ul, > ol {
    line-height: 1.8;
    margin-bottom: $line-height-computed / 2;
    @media (min-width: $screen-sm) {
      margin-bottom: $line-height-computed;
    }
  }
}

#gmaps {
  margin-bottom: $line-height-computed / 2;
  @media (min-width: $screen-sm) {
    margin-bottom: $line-height-computed;
  }
}

.canvas_map {
  position: relative;
	height: 0;
  overflow: hidden;
  cursor: pointer;
  padding-bottom: 100%; /* 1:1 */
  @media (min-width: $screen-sm) {
    padding-bottom: 75%; /* 4:3 */
  }
}

.gmnoprint {
  .gm-style-mtc {
    display: none;
  }
}
