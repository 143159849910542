// Util

// Extensions
%img-responsive {
  max-width: 100%;
  height: auto;
}

%list-unstyled {
  list-style: none;
  padding-left: 0;
}

%list-inline {
  > li {
    display: inline-block;
  }
}

%dl-unstyled {
  > dt {
    font-weight: normal;
  }
}

%dl-inline {
  margin-bottom: 0;
  > dt, > dd {
    display: inline-block;
  }
}

%hover-to-fade {
  transition: opacity .1s linear;
  &:hover {
    opacity: .5;
  }
}

%break-all {
  white-space: normal;
  word-break: break-all;
}

%break-word {
  white-space: normal;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

%word-wrap {
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word;
}

%row-gapless {
  margin-right: 0;
  margin-left: 0;
}

%column-gapless {
  padding-right: 0;
  padding-left: 0;
}

%container-base {
  margin-left: auto;
  margin-right: auto;
}

%container-xs {
  @extend %container-base;
  max-width: 480px;
}

%container-sm {
  @extend %container-base;
  max-width: 640px;
}

%container-md {
  @extend %container-base;
  max-width: 720px;
}

%container-lg {
  @extend %container-base;
  max-width: 960px;
}

%container-xl {
  @extend %container-base;
  max-width: 1280px;
}

// Mixins
@mixin grid-gapless() {
  @extend %row-gapless;
  & > [class^="col-"],
  & > [class*=" col-"] {
    @extend %column-gapless;
  }
}

@mixin word-wrap() {
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

@mixin ellipsis ($max-height, $lines: 2) {
  overflow: hidden;
  max-height: $max-height;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  text-overflow: -o-ellipsis-lastline;
}
