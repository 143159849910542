h1, h2, h3, h4, h5, h6 {
  font-family: $headings-font-family;
}

img {
  max-width: 100%;
  height: auto;
}

small {
  display: inline-block;
  line-height: $line-height-small;
}

a {
  outline: none;
  &:hover {
    color: $link-hover-color;
  }
  &:visited, &:focus {
    color: $link-color;
  }
}

dt {
  font-weight: 500;
}

.dl-horizontal {
  > dt {
    text-align: left;
  }
}
