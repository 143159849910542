// Navigation
.nav {
  font-family: $nav-font-family;
  > li {
    @include clearfix;
    > a {
      padding: 0;
    }
  }
}

@media (min-width: $screen-sm) {
  body.is-menu-overflow .nav-primary {
    position: absolute;
  }
}

.nav-primary {
  z-index: 10;
  @media (max-width: $screen-xs-max) {
    display: none;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &.is-open { display: block; }
    .nav {
      width: calc(100vw - 40px);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      li {
        text-align: center;
      }
    }
  }
  @media (min-width: $screen-sm) {
    position: fixed;
    top: $header-gutter;
    right: $header-gutter;
    margin-top: -10px;
    li {
      text-align: right;
    }
  }
  li {
    &.active {
      > a { color: $text-color; }
    }
    a {
      @extend %break-word;
      font-size: 22px;
      line-height: 1;
      font-weight: 500;
      color: $link-color;
      padding: 10px 0;
      &:hover {
        color: $text-color;
      }
    }
  }
}

%ddmenu-base {
  overflow: hidden;
  display: block;
  box-shadow: none;
  border-radius: 0;
  width: 100%;
}

%ddmenu-is-collapsed {
  height: 0;
}

%ddmenu-is-expanded {
  height: auto;
}

// The dropdown menu (ul)
.dropdown-menu {
  @extend %ddmenu-base;
  @extend %ddmenu-is-collapsed;
  position: relative;
  margin: 0;
  padding: 0;
  border: none;
  @media (max-width: $screen-xs-max) {
    max-height: 200px;
    overflow-y: scroll;
  }
  > li {
    line-height: 1.4;
    &:first-child {
      margin-top: 10px;
      > a { padding-top: 0; }
    }
    &:last-child {
      margin-bottom: 10px;
      > a { padding-bottom: 0; }
    }
    > a {
      font-size: 20px;
      border-right: 2px solid $brand-primary;
      padding: 10px 0 7px 0;
      @media (min-width: $screen-sm) {
        padding: 10px 10px 7px 0;
      }
    }
  }
}

.caret {
  display: none;
}

// State for the dropdown
.dropdown {
  &.open {
    > .dropdown-menu {
      @extend %ddmenu-is-expanded;
    }
  }
}

a[data-toggle='collapse'] {
  position: relative;
  padding-left: 1em;
  margin-right: 1em;
  &:before {
    position: absolute;
    width: 1em;
    height: $line-height-computed;
    left: 0;
    content: '+';
  }
  &[aria-expanded='true'] {
    &:before {
      content: '\2013';
    }
  }
}

.collapsing {
  transition-duration: .1s;
  transition-timing-function: ease-in-out;
}

#icon-menu {
  position: fixed;
  top: 24px;
  right: $grid-gutter-width / 2;
  z-index: 999;
  width: 30px;
  height: 20px;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  display: block;
  @media (min-width: $screen-sm-min) {
    display: none;
  }
  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: $brand-primary;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
    &:nth-child(1) { top: 0px; }
    &:nth-child(2), &:nth-child(3) { top: 9px; }
    &:nth-child(4) { top: 18px; }
  }
  &.is-open span {
    &:nth-child(1) {
      top: 8px;
      width: 0%;
      left: 50%;
    }
    &:nth-child(2) {
      transform: rotate(45deg);
    }
    &:nth-child(3) {
      transform: rotate(-45deg);
    }
    &:nth-child(4) {
      top: 8px;
      width: 0%;
      left: 50%;
    }
  }
}

.posts-navigation {
  margin-top: $line-height-computed * 2;
  .nav-links {
    @include clearfix;
  }
  a {
    display: inline-block;
    margin-right: 10px;
  }
}
