// Card
.card {
  position: relative;
	height: 0;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: $grid-gutter-width / 2;
  @media (min-width: $screen-sm) {
    margin-bottom: $grid-gutter-width;
  }
  &.card-square   { padding-bottom: 100%; }   /* 1:1 */
  &.card-standard { padding-bottom: 75%; }    /* 4:3 */
  &.card-vista    { padding-bottom: 56.25%; } /* 16:9 */
  &.card-scope    { padding-bottom: 41.67%; } /* 12:5 */
  &:hover, &:focus {
    .card-foreground {
      opacity: 1;
      color: #fff;
      text-decoration: none;
    }
    .card-background {
      transform: scale(1.03);
      // filter: grayscale(100%);
      background-blend-mode: multiply;
      background-color: $brand-primary;
    }
  }
}

.card-foreground, .card-background {
  transition: all .2s ease-in-out;
}

.card-title {
  display: block;
  &.middle-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    @include ellipsis(#{$line-height-computed * 4}, 4);
  }
}

.card-foreground {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 0;
  color: #fff;
  padding: $grid-gutter-width / 2;
  @media (min-width: $screen-sm) {
    padding: $grid-gutter-width;
  }
}

.card-background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  z-index: 1;
  transform: scale(1);
}
